@import "../../../styles/config";
@import "../../../styles/mixins";

.cell {
  display: table-cell;
  text-align: center;
  vertical-align: top;
  height: 56px;
}

.cellpad {
  padding: 0.5rem;
}

.column-header {
  display: table-cell;
  position: relative;
  vertical-align: middle;
}

.fullwidth {
  width: 100%;
}

.head {
  margin: 0;
}

.pagination {
  padding: 1rem;
}

.row {
  margin: 0 auto;
  display: table-row;
  vertical-align: top;
}

.xbackdrop {
  @include sticky-x-backdrop;
}

.xsticky {
  @include sticky-x;
}

.ybackdrop {
  @include sticky-y-backdrop;
}

.ysticky {
  @include sticky-y;

  z-index: 2 !important;
}

.rowDark {
  background-color: rgba(0 0 0 / 56%) !important;
  color: rgba(255 255 255 / 100%) !important;
  height: 100%;
  width: 100%;

  > * {
    background-color: transparent !important;
  }

  * {
    color: rgba(255 255 255 / 100%) !important;
  }
}
